import { Translate } from "next-translate"

import { firstCard, middleCard, lastCard } from "./Card/styles.css"
import { SellSmartCardItem } from "./types"

export const firstCardItems: (t: Translate, isDesktop: boolean) => SellSmartCardItem[] = (
  t: Translate,
  isDesktop
) => [
  {
    badgeList: {
      first: {
        iconName: "leaf",
        label: t("sell:valueprop_card_evaluation_image_left_badge_garden"),
      },
      second: {
        iconName: "terrace",
        label: t("sell:valueprop_card_evaluation_image_left_badge_terrace"),
      },
      third: {
        label: "+4",
      },
    },

    className: firstCard.vertical,
    imageName: "card-valuation-1",
    imageSize: isDesktop
      ? {
          height: 192,
          width: 160,
        }
      : {
          height: 153,
          width: 128,
        },
    price: t("sell:valueprop_card_evaluation_image_left_price"),
  },
  {
    badgeList: {
      first: {
        iconName: "covered_parking",
        label: t("sell:valueprop_card_evaluation_image_center_badge_parking"),
      },
      second: {
        iconName: "balcony",
        label: t("sell:valueprop_card_evaluation_image_center_badge_balcony"),
      },
      third: {
        label: "+3",
      },
    },
    className: middleCard,
    imageName: "card-valuation-2",
    imageSize: isDesktop
      ? {
          height: 240,
          width: 200,
        }
      : {
          height: 192,
          width: 160,
        },
    price: t("sell:valueprop_card_evaluation_image_center_price"),
  },
  {
    badgeList: {
      first: {
        iconName: "elevator",
        label: t("sell:valueprop_card_evaluation_image_right_badge_elevator"),
      },
      second: {
        iconName: "concierge",
        label: t("sell:valueprop_card_evaluation_image_right_badge_concierge"),
      },
      third: {
        label: "+2",
      },
    },
    className: lastCard.vertical,
    imageName: "card-valuation-3",
    imageSize: isDesktop
      ? {
          height: 192,
          width: 160,
        }
      : {
          height: 153,
          width: 128,
        },
    price: t("sell:valueprop_card_evaluation_image_right_price"),
  },
]

export const secondCardItems: (isDesktop: boolean) => SellSmartCardItem[] = (isDesktop) => [
  {
    className: firstCard.horizontal,
    imageName: "card-fees-1",
    imageSize: isDesktop
      ? {
          height: 154,
          width: 184,
        }
      : {
          height: 153,
          width: 128,
        },
  },
  {
    className: middleCard,
    imageName: "card-fees-2",
    imageSize: isDesktop
      ? {
          height: 240,
          width: 200,
        }
      : {
          height: 192,
          width: 160,
        },
  },
  {
    className: lastCard.horizontal,
    imageName: "card-fees-3",
    imageSize: isDesktop
      ? {
          height: 154,
          width: 184,
        }
      : {
          height: 153,
          width: 128,
        },
  },
]

export const getDrawerServiceList = (t: Translate, lang: string) => [
  {
    items: [
      "sell:valueprop_modal_fees_burocracy_checklist_body_01",
      "sell:valueprop_modal_fees_burocracy_checklist_body_02",
    ],
    title: "sell:valueprop_modal_fees_burocracy_title",
  },
  {
    items: [
      "sell:valueprop_modal_fees_enhance_checklist_body_01",
      "sell:valueprop_modal_fees_enhance_checklist_body_02",
      "sell:valueprop_modal_fees_enhance_checklist_body_03",
    ],
    title: "sell:valueprop_modal_fees_enhance_title",
  },
  {
    items: [
      "sell:valueprop_modal_fees_broker_checklist_body_01",
      "sell:valueprop_modal_fees_broker_checklist_body_02",
      "sell:valueprop_modal_fees_broker_checklist_body_03",
      ...(lang === "fr" ? ["sell:valueprop_modal_fees_broker_checklist_body_04"] : []),
    ],
    title: "sell:valueprop_modal_fees_broker_title",
  },
]
