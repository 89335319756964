interface CircleCheckProps extends React.SVGProps<SVGSVGElement> {
  checkColor?: string
  circleColor?: string
}

export const CircleCheck: React.FC<CircleCheckProps> = ({
  checkColor = "#F6F6F1",
  circleColor = "#1D1D1B",
  ...props
}) => (
  <svg fill="none" height="38" viewBox="0 0 38 38" width="38" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="19" cy="19" fill={circleColor} r="17.5" stroke="white" strokeWidth="3" />
    <path
      clipRule="evenodd"
      d="M26.1313 11L27.889 12.5888L16.3427 27L10.1112 21.4357L11.5964 19.5592L16.0711 23.5543L26.1313 11Z"
      fill={checkColor}
      fillRule="evenodd"
    />
  </svg>
)
