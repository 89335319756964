import { Body, H2 } from "@casavo/habitat"
import React, { ReactNode } from "react"

import { MyImage } from "@app/shared/components/MyImage"
import { fadeInFromBottom } from "@app/shared/styles/js/fadeInFromBottom.css"

import {
  benefitContent,
  benefitContentReverse,
  benefitDescription,
  benefitImage,
  benefitNote,
  benefitNoteIcon,
  benefitWrapper,
  sideImageBadge,
  sideImageContainer,
  sideImageContainerReverse,
} from "./styles.css"

type BenefitProps = {
  badgeSlot?: ReactNode
  description: string
  note: string
  reverse?: boolean
  sideImageAlt: string
  sideImageSrc: string
  title: string
}
export const Benefit: React.FC<BenefitProps> = ({
  badgeSlot,
  description,
  note,
  reverse = false,
  sideImageAlt,
  sideImageSrc,
  title,
}) => {
  return (
    <div className={`${benefitContent} ${reverse ? benefitContentReverse : ""} ${fadeInFromBottom}`}>
      <div className={`${sideImageContainer} ${reverse ? sideImageContainerReverse : ""}`}>
        <MyImage alt={sideImageAlt} className={benefitImage} height={377} src={sideImageSrc} width={576} />
        {badgeSlot && <div className={sideImageBadge}>{badgeSlot}</div>}
      </div>
      <div className={benefitWrapper}>
        <H2 noMargin>{title}</H2>
        <div className={benefitDescription}>
          <Body noMargin size="l">
            {description}
          </Body>
        </div>
        <div className={benefitNote}>
          <MyImage
            alt="note icon"
            className={benefitNoteIcon}
            height={44}
            src="/images/home/why-sell-with-casavo/note.svg"
            width={44}
          />
          <Body noMargin html={note} size="s" />
        </div>
      </div>
    </div>
  )
}
