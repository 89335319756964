import { useBgColor } from "@app/shared/utils/context/useBgColor"
import { Benefits } from "./Benefits"
import { Faq } from "./Faq"
import { GoogleReviews } from "./GoogleReviews"
import { HomeHero } from "./Hero"
import { HomePress } from "./Press"
import { Steps } from "./Steps"
import { homeSeparator } from "./styles.css"
import { ValuePropositionSection } from "./ValueProposition"
import { AbTestVariant } from "@app/pages/home-street-number/[testVariant]"

type NewHomePageProps = {
  abTestVariant?: AbTestVariant
  updateAddressInputVisibility: (visibility: boolean) => void
  updateHeroVisibility: (visibility: boolean) => void
}
export const NewHomePage: React.FC<NewHomePageProps> = ({
  abTestVariant,
  updateAddressInputVisibility,
  updateHeroVisibility,
}) => {
  const { bgColor } = useBgColor()
  return (
    <main style={{ backgroundColor: bgColor, transition: "background-color .6s ease-in-out" }}>
      <HomeHero
        abTestVariant={abTestVariant}
        updateAddressInputVisibility={updateAddressInputVisibility}
        updateHeroVisibility={updateHeroVisibility}
      />
      <hr className={homeSeparator} />
      <Steps />
      <ValuePropositionSection />
      <Benefits />
      <Faq />
      <GoogleReviews />
      <HomePress />
    </main>
  )
}
