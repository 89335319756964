import { Body, Button } from "@casavo/habitat"
import Link from "next/link"

import { sprinkles } from "@app/shared/design-lib/style-theme/sprinkles.css"
import { useHTMLDialogElementSupported } from "@app/shared/hooks/useHTMLDialogElementSupported"
import { handleUserEventClick } from "@app/shared/utils/tracking/userEvent"

import { ctaButton, ctaWrapper } from "./styles.css"

type HomeStickyCtaProps = {
  addressInputIsVisible: boolean
  description: string
  gtmEvent: { label?: string; value: string }
  label: string
  onCtaClick?: () => void
  showDescriptionOnMobile?: boolean
  url?: string
}

export const HomeStickyCta: React.FC<HomeStickyCtaProps> = ({
  addressInputIsVisible,
  description,
  gtmEvent,
  label,
  onCtaClick,
  showDescriptionOnMobile,
  url,
}) => {
  const { isHTMLDialogElementSupported } = useHTMLDialogElementSupported()
  const { label: gtmLabel, value: gtmValue } = gtmEvent

  if (!isHTMLDialogElementSupported) {
    return null
  }

  const handleCtaClick = () => {
    onCtaClick?.()
    handleUserEventClick(gtmValue, gtmLabel)
  }
  return (
    <>
      {label !== "" && (
        <section className={`${ctaWrapper} ${addressInputIsVisible ? "" : "visible"}`}>
          <div
            className={`${sprinkles({
              display: { desktop: "block", mobile: showDescriptionOnMobile ? "block" : "none" },
              paddingBottom: { desktop: 0, mobile: "m" },
              textAlign: { desktop: "left", mobile: "center" },
            })}`}
          >
            <Body noMargin strong>
              {description}
            </Body>
          </div>
          {url ? (
            <Link aria-label={label} href={url}>
              <div className={ctaButton}>
                <Button onPress={handleCtaClick}>{label}</Button>
              </div>
            </Link>
          ) : (
            <div>
              <div className={ctaButton}>
                <Button onPress={handleCtaClick}>{label}</Button>
              </div>
            </div>
          )}
        </section>
      )}
    </>
  )
}
