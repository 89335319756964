import { Body } from "@casavo/habitat"

import { MyImage } from "@app/shared/components/MyImage"
import { sprinkles } from "@app/shared/design-lib/style-theme/sprinkles.css"

import { BadgeList } from "../types"

import { badgeWrapper, badge } from "./styles.css"

type Props = {
  badgeList: BadgeList
}

export const Badge: React.FC<Props> = ({ badgeList }) => {
  return (
    <div className={badgeWrapper}>
      <div className={`${badge} ${sprinkles({ marginBottom: "xs" })}`}>
        <MyImage alt="" height={16} src={`/images/icons/${badgeList.first.iconName}.svg`} width={16} />
        <Body noMargin size="xs">
          {badgeList.first.label}
        </Body>
      </div>
      <div className={sprinkles({ display: "flex", gap: "xs" })}>
        <div className={badge}>
          <MyImage alt="" height={16} src={`/images/icons/${badgeList.second.iconName}.svg`} width={16} />
          <Body noMargin size="xs">
            {badgeList.second.label}
          </Body>
        </div>
        <div className={badge}>
          <Body noMargin size="xs">
            {badgeList.third.label}
          </Body>
        </div>
      </div>
    </div>
  )
}
