import { Content, DialogProps, Overlay, Portal, Root } from "@radix-ui/react-dialog"
import React from "react"

import { styledContent, styledOverlay } from "./styles.css"

type DrawerProps = DialogProps

export const Drawer: React.FC<DrawerProps> = ({ children, ...rest }) => {
  return (
    <Root {...rest}>
      <Portal>
        <Overlay className={styledOverlay} />
        <Content className={styledContent}>{children}</Content>
      </Portal>
    </Root>
  )
}
