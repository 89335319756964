import { H1, vars } from "@casavo/habitat"
import { Translate } from "next-translate"
import useTranslation from "next-translate/useTranslation"
import React, { useRef } from "react"

import { Carousel } from "@app/components/carousel"
import { MyImage } from "@app/shared/components/MyImage"
import { useChangeBgColorInView } from "@app/shared/hooks/useChangeBgColorInView"
import { fadeInFromBottom } from "@app/shared/styles/js/fadeInFromBottom.css"
import { handleUserEventClick } from "@app/shared/utils/tracking/userEvent"

import { ReviewCard } from "./ReviewCard"
import {
  googleReviewsSection,
  googleReviewsWrapper,
  sectionTitle,
  desktopReviews,
  mobileReviews,
} from "./styles.css"

export const getReviews = (t: Translate) => [
  {
    content: t("sell:reviews_card_01_body"),
    name: t("sell:reviews_card_01_author"),
  },
  {
    content: t("sell:reviews_card_02_body"),
    name: t("sell:reviews_card_02_author"),
  },
  {
    content: t("sell:reviews_card_03_body"),
    name: t("sell:reviews_card_03_author"),
  },
]

export const GoogleReviews: React.FC = () => {
  const { lang, t } = useTranslation()
  const REVIEWS = getReviews(t)

  const { ref: targetRef } = useChangeBgColorInView(vars.colors.background.default.dark)

  const handeCarouselSwipe = (idx: number) =>
    handleUserEventClick(`ReviewsCarousel-SwipeOnCarousel0${idx + 1}`, "Homepage", "Swipe")

  const onClickHandler =
    lang !== "fr"
      ? undefined
      : () => {
          window.open("https://www.opinionsystem.fr/fr-fr/search/group/1584", "_blank")?.focus()
        }

  return (
    <div className={googleReviewsSection}>
      <div ref={targetRef} className={`${googleReviewsWrapper} ${fadeInFromBottom}`}>
        <MyImage
          alt="google reviews logo"
          height={79}
          src={lang === "fr" ? "/images/home/reviews/opinion.png" : "/images/home/reviews/google-review.png"}
          style={{
            cursor: onClickHandler ? "pointer" : "default",
          }}
          width={140}
          onClick={onClickHandler}
        />
        <div className={sectionTitle}>
          <H1 noMargin>{t("sell:reviews_header")}</H1>
        </div>
        <div className={desktopReviews}>
          {REVIEWS.map((review, idx) => (
            <ReviewCard key={`${review.name}-${idx}`} review={review} onClick={onClickHandler} />
          ))}
        </div>
        <div className={mobileReviews}>
          <Carousel onSwipe={handeCarouselSwipe}>
            {REVIEWS.map((review, idx) => (
              <ReviewCard
                key={`${review.name}-${idx}-mobile`}
                className="mobile"
                review={review}
                onClick={onClickHandler}
              />
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  )
}
