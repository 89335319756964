import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fshared%2Fcomponents%2FAddressAutocomplete%2FAddressAutoCompleteStreetNumber%2Fstyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA61W227cIBB9369AqiolUr1a3zfkpVqlkfoFfaxYG9s0NiDAWW%2Bq%2FHsFvuLLpqqaPCTAnJkzw5nB%2B5%2BuR1We8QP4vQOAM0kUYRQCgUukyCt%2B3AHw5hCa4gYC9%2FC4e9%2Fte4z7txgALiRVhV4cPlsuPOMiJZKX6AoBZRRb5%2F4H54E5RyXJqUMUriQEUgmskuJxistK3OgN%2FddJicBJyzhhZV1RfZIjDsErEneOczkeqR%2F75F7vV0jkhEJwAKhWzIod3kh%2FK9%2FIYEqcKQiO3HAaHaCzZGWtjAPFOASBtpigY4M%2Bo%2BQlF6ymqZOwkgkIPj2bH407M5FiAYHLGyBZSVLw6eSf%2FJM3HjoCpaSWnfu2KBC45l9GlZOhipRXCL5ThcWwK8kbhsDtSBeY5IWCIOx8cJSmhOYQeB5vdGa9861CxDBjSS3%2FKSH3yX1yT%2FqQ1aokFK8II97Lgl1%2BIEEJzdsonSNvdPQtjIIwsnDHpaAS3BdioSejmi7TQSntonH6GpkNxlFC1LVfvWKRlewCQUHSFBsFKoFor4TWF9j78kuPbBejX722mS8y7hkFvFmy8nFl8XItZw%2B3yjDeF5xexrq6lje6qGJPKeogv2qpSHZ1EkYVpmoaetCZ%2B8Ab4HstQOFGOYbs1HZa0LnEwN6VRp4FUdiRHCVGRBeBuF0HiBLd01s6Db3QC2elg4W%2B3i1E7Md%2B7FkI9H%2FbOrILP15QO3YOo1gdM2WOVgu3e97N2TQpt%2FY5zKvwg6Y%2Fm0STWkjNjDNiTZjF3BkvW%2FesG82m4fl2oZ9D%2FWshEvs1OZcseRnCXzoVnlmZTkp0ZkqxajmL0zaXRZE3vHfjc%2B6lFdaQqB6cbbIryhaan4XO7HwI1cPQGQL3fdVH%2FVrhlCAgE4ExBYim4K4i1Bluywt4c29czp744Ub96NDyep8aeZ3RxrhY6fjt97YdUl24%2BNCF23pmLRr%2BGo3J18Aqj%2B0vglscO36BF6yUI%2Bh4zL5aZlZxZzUffrOHxMI8dJixMWI9BeMVEqgzNX0Zeb3F%2Bx974FYY8QkAAA%3D%3D%22%7D"
export var autoComplete = '_12ntgfp0';
export var autoCompleteStreetNumber = '_12ntgfp1';
export var formButton = '_12ntgfp9';
export var formDesktop = {abTestVersionControl:'_12ntgfp2',abTestVersionB:'_12ntgfp3'};
export var formInput = '_12ntgfp7';
export var formInputWrapper = '_12ntgfp5';
export var formLabel = '_12ntgfp6';
export var formMobile = '_12ntgfp4';
export var formWarning = '_12ntgfp8';
export var suggestionsGoogleLogo = '_12ntgfpf';
export var suggestionsGoogleLogoWrapper = '_12ntgfpe';
export var suggestionsItem = '_12ntgfpb';
export var suggestionsItemCity = '_12ntgfpd';
export var suggestionsItemStreet = '_12ntgfpc';
export var suggestionsList = '_12ntgfpa';