import getConfig from "next/config"

import { sprinkles } from "@app/shared/design-lib/style-theme/sprinkles.css"

import { heroImagePicture, heroImageWrapper } from "./styles.css"

export const HeroImage: React.FC<{
  images: { desktop: string; mobile: string }
}> = ({ images }) => {
  const {
    publicRuntimeConfig: { imageBasePath, imgixDefaultOptions },
  } = getConfig()
  const { desktop: desktopImage, mobile: mobileImage } = images
  const common = { alt: "hero", sizes: "(min-width: 768px) 50vw, 80vw" }

  return (
    <div className={heroImageWrapper}>
      <picture className={heroImagePicture}>
        <source
          height={238}
          media="(max-width: 767px)"
          srcSet={`${imageBasePath}${mobileImage}?${imgixDefaultOptions}`}
          width={372}
        />
        <source
          height={480}
          media="(min-width: 768px)"
          srcSet={`${desktopImage}?${imgixDefaultOptions}`}
          width={667}
        />
        <img
          alt={common.alt}
          className={sprinkles({ width: "100%" })}
          height={480}
          src={`${imageBasePath}${mobileImage}?${imgixDefaultOptions}`}
          width={667}
        />
      </picture>
    </div>
  )
}
