import { Body, H1 } from "@casavo/habitat"
import useTranslation from "next-translate/useTranslation"
import { FC, useEffect } from "react"
import { useInView } from "react-intersection-observer"

import { AddressAutocomplete } from "@app/shared/components/AddressAutocomplete"
import { Typography } from "@app/shared/components/Typography"
import { useHTMLDialogElementSupported } from "@app/shared/hooks/useHTMLDialogElementSupported"

import {
  homeHero,
  homeHeroContent,
  homeHeroContentTitle,
  homeHeroContentTitleMaxWidth,
  homeHeroSection,
} from "../styles.css"

import { HeroImage } from "./HeroImage"
import { AbTestVariant } from "@app/pages/home-street-number/[testVariant]"
import { AddressAutocompleteStreetNumberInput } from "@app/shared/components/AddressAutocomplete/AddressAutoCompleteStreetNumber"

export type HomeHeroProps = {
  abTestVariant?: AbTestVariant
  updateAddressInputVisibility: (visibility: boolean) => void
  updateHeroVisibility: (visibility: boolean) => void
}

export const HomeHero: FC<HomeHeroProps> = ({
  abTestVariant = "control",
  updateAddressInputVisibility,
  updateHeroVisibility,
}) => {
  const { lang, t } = useTranslation("sell")
  const { isHTMLDialogElementSupported } = useHTMLDialogElementSupported()

  const { inView, ref } = useInView({
    initialInView: true,
  })
  const { inView: inputInView, ref: inputRef } = useInView({
    initialInView: true,
  })

  useEffect(() => {
    updateHeroVisibility(inView)
  }, [inView, updateHeroVisibility])

  useEffect(() => {
    updateAddressInputVisibility(inputInView)
  }, [inputInView, updateAddressInputVisibility])

  const localeMapping: Record<string, string> = {
    en: "en",
    fr: "fr",
    it: "it",
  }
  const localeSrc = localeMapping[lang] || "it"

  return (
    <section ref={ref} className={homeHeroSection} id="hero-section">
      <div className={homeHero}>
        <div ref={inputRef} className={homeHeroContent}>
          <div className={abTestVariant === "b" ? homeHeroContentTitleMaxWidth : ""}>
            <H1 display>{t("sell:title")}</H1>
          </div>
          {isHTMLDialogElementSupported && (
            <Body size="l" strong>
              {t("sell:subtitle")}
            </Body>
          )}
          {abTestVariant === "b" ? <AddressAutocompleteStreetNumberInput /> : <AddressAutocomplete />}
        </div>
        <HeroImage
          images={{
            desktop: `/images/home/hero-${localeSrc}.webp`,
            mobile: `/images/home/hero-mobile-${localeSrc}.webp`,
          }}
        />
      </div>
    </section>
  )
}
