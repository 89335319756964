import { Body, H1, vars } from "@casavo/habitat"
import { Translate } from "next-translate"
import useTranslation from "next-translate/useTranslation"
import React, { useRef } from "react"

import { MyImage } from "@app/shared/components/MyImage"
import { useChangeBgColorInView } from "@app/shared/hooks/useChangeBgColorInView"
import { fadeInFromBottom } from "@app/shared/styles/js/fadeInFromBottom.css"

import { Benefit } from "./Benefit"
import {
  badge,
  badgeContent,
  badgeHeader,
  badgeLogo,
  badgeLogoGroup,
  benefitsSection,
  contentWrapper,
} from "./styles.css"

type BenefitsProps = {}

export const Benefits: React.FC<BenefitsProps> = () => {
  const { lang, t } = useTranslation()

  const { ref: targetRef } = useChangeBgColorInView(vars.colors.background.default.light)

  return (
    <section ref={targetRef} className={`${fadeInFromBottom} ${benefitsSection}`}>
      <H1 noMargin>{t("sell:benefit_header")}</H1>
      <div className={contentWrapper}>
        <Benefit
          reverse
          badgeSlot={NotificationBadge(t)}
          description={t("sell:benefit_section_burocracy_body")}
          note={t("sell:benefit_section_burocracy_highlight_body")}
          sideImageAlt="person holding up a phone with a push notification"
          sideImageSrc={
            lang === "fr"
              ? "/images/home/why-sell-with-casavo/due-diligence-fr.png"
              : "/images/home/why-sell-with-casavo/due-diligence.png"
          }
          title={t("sell:benefit_section_burocracy_headline")}
        />
        <Benefit
          badgeSlot={
            lang === "fr" && (
              <MyImage fill alt="" src="/images/home/why-sell-with-casavo/shooting-fr-badge.png" />
            )
          }
          description={t("sell:benefit_section_enhance_body")}
          note={t("sell:benefit_section_enhance_highlight_body")}
          sideImageAlt="A living room with wood floor, nordic forniture and lots of plants"
          sideImageSrc={
            lang === "fr"
              ? "/images/home/why-sell-with-casavo/shooting-fr.png"
              : "/images/home/why-sell-with-casavo/shooting.png"
          }
          title={t("sell:benefit_section_enhance_headline")}
        />
        <Benefit
          reverse
          description={t("sell:benefit_section_broker_body")}
          note={t("sell:benefit_section_broker_highlight_body")}
          sideImageAlt="A broker holding a casavo tablet, opening the door for a couple"
          sideImageSrc="/images/home/why-sell-with-casavo/broker.png"
          title={t("sell:benefit_section_broker_headline")}
        />
      </div>
    </section>
  )
}

const NotificationBadge = (t: Translate) => (
  <div className={badge}>
    <div className={badgeHeader}>
      <div className={badgeLogoGroup}>
        <MyImage
          alt="casavo logo"
          className={badgeLogo}
          height={18}
          src="/images/match/casavo.svg"
          width={18}
        />
        <Body noMargin>CASAVO</Body>
      </div>
      <Body noMargin>{t("sell:benefit_section_burocracy_image_notification_timing")}</Body>
    </div>
    <div className={badgeContent}>
      <Body noMargin>{t("sell:benefit_section_burocracy_image_notification_body")}</Body>
    </div>
  </div>
)
