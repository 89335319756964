import Script from "next/script"
import useTranslation from "next-translate/useTranslation"
import { useState } from "react"

import { BgColorProvider } from "@app/shared/utils/context/useBgColor"
import { useSaveMarketingCampaignParams } from "@app/shared/utils/marketingServerToServer"

import { NewHomePage } from "./NewHomePage"
import { HomeStickyCta } from "./StickyCta"
import { AbTestVariant } from "@app/pages/home-street-number/[testVariant]"

interface HomeContentProps {
  abTestVariant?: AbTestVariant
  updateHeroVisibility: (visibility: boolean) => void
}

export const HomeContent: React.FC<HomeContentProps> = ({ abTestVariant, updateHeroVisibility }) => {
  const { t } = useTranslation("sell")
  const { lang } = useTranslation()
  const [addressInputIsVisible, setAddressInputIsVisible] = useState(true)
  useSaveMarketingCampaignParams()

  return (
    <>
      {["fr"].includes(lang) && (
        <>
          <Script
            async
            src="//pixel.mathtag.com/event/js?mt_id=1628525&mt_adid=260491&mt_exem=&mt_excl=&v1=&v2=&v3=&s1=&s2=&s3="
          />
        </>
      )}
      <BgColorProvider>
        <NewHomePage
          abTestVariant={abTestVariant}
          updateAddressInputVisibility={setAddressInputIsVisible}
          updateHeroVisibility={updateHeroVisibility}
        />
      </BgColorProvider>
      <HomeStickyCta
        addressInputIsVisible={addressInputIsVisible}
        description={t("sticky.description")}
        gtmEvent={{ value: "StickyFooter" }}
        label={t("sticky.title")}
        url="#hero-section"
      />
    </>
  )
}
